<template>
    <div>
        <AdminTop
            :heading="userId ? 'Redigera användare' : 'Skapa användare'"
        />

        <BaseForm
            v-if="user"
            :loading="loading"
            :submit-label="userId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Bild"
                name="image"
            >
                <ImageUpload
                    :image="user.image"
                    @change="image => user.image = image"
                />
            </FormGroup>

            <FormGroup
                label="Namn"
                name="name"
            >
                <InputField
                    v-model="user.name"
                    name="name"
                    type="text"
                    required
                />
            </FormGroup>

            <FormGroup
                label="E-post"
                name="email"
            >
                <InputField
                    v-model="user.email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                />
            </FormGroup>

            <FormGroup
                v-if="!user.roles || !user.roles.find(role => role.name === 'admin')"
                label="Mentorer"
                name="mentors"
            >
                <MultipleSelectField
                    v-if="mentors"
                    v-model="user.mentors"
                    :items="mentors"
                    placeholder="Välj mentorer"
                />
            </FormGroup>

            <FormGroup
                v-if="!user.roles || !user.roles.find(role => role.name === 'admin')"
                label="Klass"
                name="schoolClass"
            >
                <SelectField
                    v-if="schoolClasses"
                    v-model="user.school_class_id"
                    :items="schoolClasses"
                    placeholder="Välj klass"
                />
            </FormGroup>

            <FormGroup
                :label="userId ? 'Lösenord (lämna tom om inte lösenordet ska ändras)' : 'Lösenord'"
                name="password"
            >
                <InputField
                    v-model="user.password"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    :required="userId ? null : 'true'"
                />
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import ImageUpload from '@/components/form/ImageUpload';
import MultipleSelectField from '@/components/form/MultipleSelectField';
import SelectField from '@/components/form/SelectField';
import InputField from '@/components/form/InputField';
import UserService from '@/services/admin/UserService';
import TeacherService from '@/services/admin/TeacherService';
import SchoolClassService from '@/services/admin/SchoolClassService';
import { mapGetters } from 'vuex';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        InputField,
        ImageUpload,
        MultipleSelectField,
        SelectField
    },

    data: () => ({
        loading: false,
        user: null,
        mentors: null,
        schoolClasses: null
    }),

    computed: {
        userId () {
            return this.$route.params.id;
        },

        ...mapGetters('auth', ['authUser'])
    },

    async created () {
        const [user, mentors, schoolClasses] = await Promise.all([
            this.getUser(),
            TeacherService.all(),
            SchoolClassService.all()
        ]);

        this.user = user;
        this.mentors = mentors.data;
        this.schoolClasses = schoolClasses.data;
    },

    methods: {
        async getUser () {
            if (!this.userId) {
                return {
                    name: null,
                    email: null,
                    password: null,
                    mentors: []
                };
            }

            const res = await UserService.get(this.$route.params.id);

            const user = res.data;

            user.mentors = user?.mentors?.map(mentor => mentor.id) || [];

            return user;
        },

        async handleSubmit () {
            this.loading = true;

            try {
                if (this.userId) {
                    await this.update();
                } else {
                    await this.create();
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async create () {
            const res = await UserService.create(this.user);

            if (res.status === 200 && res?.data?.user?.id) {
                this.$router.push(`/admin/users/${res.data.user.id}`);
            }
        },

        async update () {
            const res = await UserService.update(this.userId, this.user);

            if (res?.status === 200 && res?.data?.user?.id === this.authUser.id) {
                this.$store.commit('auth/SET_USER', res.data.user);
            }
        }
    }
};
</script>
